import { Container, ImageList, ImageListItem, Typography } from "@mui/material";
import { getImageListContent } from "../../util";
import { map } from "ramda";
import { Image } from "../../common";
import { useMemo } from "react";
import { useScrollRefContext } from "../../../state/scroll-ref-context";
import { useIsWindowHorizontal } from "../../hooks";

export const HomePageImageList = () => {
	const isHorizontal = useIsWindowHorizontal();
	const images = useMemo(() => getImageListContent(), []);
	const { imageGalleryRef } = useScrollRefContext();
	return (
		<Container
			ref={imageGalleryRef}
			sx={{
				height: "100%",
				overflowX: "hidden",
				overflowY: "auto",
				textAlign: "center",
			}}
		>
			<ImageList variant="masonry" cols={isHorizontal ? 2 : 1}>
				{map(
					(image) => (
						<ImageListItem>
							<Image src={image} alt={image} />
						</ImageListItem>
					),
					images,
				)}
			</ImageList>
		</Container>
	);
};
