import { Button, Card, Container, Typography, useTheme } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { cardBG } from "../../util";
import { MouseEventHandler } from "react";

export interface ContactPageItemProps {
	headerText: string;
	bodyText?: string;
	Icon: SvgIconComponent;
	iconColor?: string;
	cursor?: "pointer" | "default";
	onClick?: MouseEventHandler<HTMLDivElement>;
}

export const ContactPageItem = ({
	headerText,
	bodyText,
	Icon,
	iconColor,
	cursor = "default",
	onClick = () => {},
}: ContactPageItemProps) => {
	const { palette } = useTheme();
	return (
		<Container
			onClick={onClick}
			sx={{
				cursor: cursor,
				width: "30vh",
				minWidth: "25vw",
				minHeight: "25vw",
				backgroundColor: cardBG,
				padding: "16px",
				boxShadow: 3,
				height: "30vh",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Icon
				fontSize="large"
				sx={{
					color: iconColor ?? palette.primary.main,
				}}
			/>
			<Typography
				variant="h5"
				fontWeight={"bold"}
				color="secondary"
				whiteSpace={"nowrap"}
				paddingTop={"1rem"}
			>
				{headerText}
			</Typography>
			<Typography variant="body2" color="secondary" textTransform={"none"}>
				{bodyText}
			</Typography>
		</Container>
	);
};
