import { alpha, Container, Typography } from "@mui/material";
import { HomePageImageList, HomePageVideoList } from "./components";

export const HomePage = () => {
	return (
		<Container
			maxWidth={false}
			sx={{
				bgcolor: alpha("#231F20", 0.8),
				width: "80vw",
				maxWidth: "80vw",
				margin: "0 10vw",
				padding: "2vh 0",
				opacity: "1",
				textAlign: "center",
			}}
		>
			<HomePageImageList />
			<HomePageVideoList />
		</Container>
	);
};
