import React from "react";
import { alpha, Box, Container, Typography } from "@mui/material";
import {
	bandInfoText1,
	bandInfoText2,
	bandRoles,
	previousBands,
} from "../../texts";
import { Image } from "../common";
import { band_bw_about, casual_crop } from "../../images";
import { map, split } from "ramda";
import { useScrollRefContext } from "../../state/scroll-ref-context";

export const AboutPage = () => {
	const { aboutRef } = useScrollRefContext();
	return (
		<Container
			maxWidth={false}
			ref={aboutRef}
			sx={{
				bgcolor: alpha("#231F20", 0.8),
				width: "80vw",
				maxWidth: "80vw",
				margin: "0 10vw",
				padding: "2vh 0",
				opacity: "1",
			}}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					textAlign: "center",
				}}
			>
				<Typography padding="5%" color="#F0EAD6" fontWeight="bold" variant="h2">
					OM BANDET
				</Typography>
				<Typography color="#F0EAD6" variant="h6">
					{bandInfoText1}
				</Typography>
				<Box
					sx={{
						padding: "5% 0",
						margin: "0",
					}}
				>
					<Image src={band_bw_about} />
				</Box>
				<Typography color="#F0EAD6" variant="h6">
					{bandInfoText2}
				</Typography>
				<Box
					sx={{
						padding: "10% 0",
						textAlign: "right",
					}}
				>
					<Typography
						paddingBottom={"2%"}
						color="#F0EAD6"
						fontWeight="bold"
						variant="h3"
					>
						BANDET
					</Typography>
					{map((bandMemberRole: string) => {
						return (
							<Typography color="#F0EAD6" variant="h4">
								{bandMemberRole}
							</Typography>
						);
					}, split("\n", bandRoles))}
				</Box>
				<Box
					sx={{
						padding: "5% 0",
						margin: "0",
					}}
				>
					<Image src={casual_crop} />
				</Box>
				<Box
					sx={{
						paddingBottom: "10%",
						textAlign: "left",
					}}
				>
					<Typography
						paddingBottom={"2%"}
						color="#F0EAD6"
						fontWeight="bold"
						variant="h5"
					>
						BAND SOM VI SPELAT I GENOM ÅREN
					</Typography>
					{map((prevBand: string) => {
						return <Typography color="#F0EAD6">{prevBand}</Typography>;
					}, previousBands)}
				</Box>
			</Container>
		</Container>
	);
};
