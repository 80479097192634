interface ImageProps {
	src: string;
	alt?: string;
}

export const Image = ({ src, alt }: ImageProps) => {
	return (
		<img
			style={{
				objectFit: "contain",
				opacity: "1",
			}}
			src={src}
			alt={alt}
			height={"100%"}
			width={"100%"}
		/>
	);
};
