import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box, responsiveFontSizes, ThemeProvider } from "@mui/material";

import { ContactPage, HomePage, AboutPage } from "../frontend";
import { theme } from "../theme";
import { Header, SpeedDialMenu } from "../frontend/common";
import { ScrollRefProvider } from "../state/scroll-ref-context";
import { useRef } from "react";

const bgImage = require("../images/background.jpg");

const BeatsWebPage = () => {
	const imageGalleryRef = useRef<HTMLDivElement>(null);
	const videoGalleryRef = useRef<HTMLDivElement>(null);
	const contactRef = useRef<HTMLDivElement>(null);
	const aboutRef = useRef<HTMLDivElement>(null);
	return (
		<ThemeProvider theme={responsiveFontSizes(theme)}>
			<ScrollRefProvider
				value={{ imageGalleryRef, videoGalleryRef, contactRef, aboutRef }}
			>
				<Box
					sx={{
						width: "100vw",
						backgroundImage: `url('${bgImage}')`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						filter: "blur",
					}}
				>
					<Box
						sx={{
							overflowX: "hidden",
							width: "100vw",
							maxWidth: "100vw",
							height: "100vh",
							margin: "0",
							padding: "0",
						}}
					>
						<SpeedDialMenu />
						<Header />
						<HomePage />
						<AboutPage />
						<ContactPage />
					</Box>
				</Box>
			</ScrollRefProvider>
		</ThemeProvider>
	);
};

export default BeatsWebPage;
