import { Context, createContext, RefObject, useContext, useRef } from "react";

export interface ScrollRefContextParams {
	imageGalleryRef: RefObject<HTMLDivElement>;
	videoGalleryRef: RefObject<HTMLDivElement>;
	contactRef: RefObject<HTMLDivElement>;
	aboutRef: RefObject<HTMLDivElement>;
}

export const ScrollRefContext: Context<ScrollRefContextParams> = createContext({
	imageGalleryRef: {} as RefObject<HTMLDivElement>,
	videoGalleryRef: {} as RefObject<HTMLDivElement>,
	contactRef: {} as RefObject<HTMLDivElement>,
	aboutRef: {} as RefObject<HTMLDivElement>,
});

export const ScrollRefProvider = ScrollRefContext.Provider;

export const useScrollRefContext = () => useContext(ScrollRefContext);
