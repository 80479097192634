export const bandInfoText1 =
	"Vi är 4 män i våra bästa år som har spelat musik ihop sedan vi gick i högstadiet, redan då spelade 3 av oss tillsammans ett par år, det är bara trumslagaren som kommit med på senare år, vår gamla trummis flyttade till Norge. Sedan gick vi skilda vägar och spelade i ett flertal andra konstellationer, jazzband, dansband, trubadur, coverband, hårdrock etc. Vi är oerhört rutinerade. Nu sedan ett par år har vi hittat tillbaka till varandra, och har oerhört kul på scen tillsammans och med publiken.";

export const bandInfoText2 =
	"Vi har spelat en hel del på festivaler som Fallens dagar i Trollhättan, Smaka på Trollhättan och på ett flertal krogar i västra Götaland och Dalsland. Det som gäller är att vi bara spelar 80 tals låtar, bara gamla hits med massor av allsång och upptåg, gärna helt koncept då publiken får komma utklädd, pris t bästa kostym, maten på stället får gärna vara från 80 talet, så blir det ett underbart tema. Vi är noga med att det skall vara bra ljud och ljus på våra spelningar, och har all utrustning själva om det inte finns på plats där vi spelar. Vi är oerhört uppskattade överallt, och har bara haft fulla hus på alla spelningar vi haft hittills.";

export const bandRoles =
	"Göran Eklund - Bas och stämsång\nMarko Kallunki - trummor\nMikael Stjernqvist - sång och gitarr\nPeter Wall - Gitarr och stämsång";

export const previousBands = [
	"Cold Sweat - soulband som var husband på Valand i många år",
	"Husband hos Filip & Fredrik på kanal 5",
	"The Bandet - husband på Gamleport de sista 5 åren det fanns, innan dess 5-6 år på Centralens pub i Göteborg och på Strömmabåtarna & Älvsborgs Nya Fästning",
	"Bröderna Hallberg - lokalt partyband i Trollhättan",
	"Ringos - Beatlestolkare",
	"Trubadurerna X & Y, duo med hundratals spelningar per år",
	"Även jobbat mycket som ensam trubadur med bara akustisk gitarr & sång, mycket prata, skoja, leda quiz",
	"Massor av spelningar på i princip alla krogar i Göteborg",
	"Företagsevent och mässor",
	"After-ski på de flesta ställen Sälen och lite i Norge",
];
