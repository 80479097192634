export const casual_1 = require("./casual_1.jpg");
export const casual_2 = require("./casual_2.jpg");
export const on_stage_1 = require("./on_stage_1.jpg");
export const on_stage_2 = require("./on_stage_2.jpg");
export const band_bw = require("./band-bw.jpg");
export const crowd_flower = require("./crowd-flower.jpg");
export const mio_poster = require("./mio-poster.jpg");

export const images = [
	casual_1,
	casual_2,
	on_stage_1,
	mio_poster,
	on_stage_2,
	band_bw,
	crowd_flower,
] as string[];
