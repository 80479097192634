import { Email, Facebook, Phone } from "@mui/icons-material";
import { Box, Card, Container, Typography, useTheme } from "@mui/material";
import { useIsWindowHorizontal } from "../../hooks";
import { useScrollRefContext } from "../../../state/scroll-ref-context";
import { cardBG, fbURL } from "../../util";
import { ContactPageItem } from "./contact-page-item";

export const ContactPageBody = () => {
	const isHorizontal = useIsWindowHorizontal();
	const { contactRef } = useScrollRefContext();
	return (
		<Container
			sx={{
				alignItems: "center",
				flexDirection: "column",
				padding: "10%",
			}}
			ref={contactRef}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: isHorizontal ? "row" : "column",
					flexWrap: "wrap",
					gap: "10vw",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ContactPageItem
					headerText="Mikaels Email"
					bodyText="mikael.stjernqvist@gmail.com"
					Icon={Email}
				/>
				<ContactPageItem
					headerText="Peters Email"
					bodyText="peter.wall@me.com"
					Icon={Email}
				/>
				<ContactPageItem
					headerText="Mikaels tfnr."
					bodyText="+4673-9500700"
					Icon={Phone}
				/>
				<ContactPageItem
					headerText="Peters tfnr."
					bodyText="+46707400900"
					Icon={Phone}
				/>
				<ContactPageItem
					onClick={() => window.open(fbURL)}
					cursor="pointer"
					headerText="Facebook"
					bodyText="BEATS 80"
					iconColor="#1877F2"
					Icon={Facebook}
				/>
			</Container>
		</Container>
	);
};
