import {
	Box,
	Card,
	Container,
	ImageList,
	ImageListItem,
	List,
	ListItem,
	Typography,
} from "@mui/material";
import { getVideoListContent } from "../../util";
import { map } from "ramda";
import { useMemo } from "react";
import { useScrollRefContext } from "../../../state/scroll-ref-context";
import { useIsWindowHorizontal } from "../../hooks";
import ReactPlayer from "react-player";

export const HomePageVideoList = () => {
	const videos: string[] = useMemo(() => getVideoListContent(), []);
	const { videoGalleryRef } = useScrollRefContext();
	return (
		<Container
			ref={videoGalleryRef}
			sx={{
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				justifyContent: "center",
				textAlign: "center",
			}}
		>
			{map((video) => {
				return (
					<Box padding={"2% 0"}>
						<ReactPlayer width={"100%"} controls={true} url={video} />
					</Box>
				);
			}, videos)}
		</Container>
	);
};
