import { SpeedDial, SpeedDialAction, useTheme } from "@mui/material";
import {
	Facebook,
	Email,
	Menu,
	Collections,
	VideoLibrary,
	Info,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { fbURL, mainColor, secondaryColor } from "../util";
import { useScrollRefContext } from "../../state/scroll-ref-context";
import { MutableRefObject, RefObject, useRef } from "react";
import { isNil } from "ramda";

interface CustomSpeedDialActionProps {
	icon: JSX.Element;
	name: string;
	scrollRef?: RefObject<HTMLDivElement>;
}

const getSpeedDialAction = (action: CustomSpeedDialActionProps) => {
	const handleClick = () =>
		!isNil(action.scrollRef)
			? action.scrollRef.current?.scrollIntoView({
					behavior: "smooth",
					block: "start",
			  })
			: window.open(fbURL);
	return (
		<SpeedDialAction
			key={action.name}
			icon={action.icon}
			tooltipTitle={action.name}
			onClick={handleClick}
		/>
	);
};

export const SpeedDialMenu = () => {
	const { palette } = useTheme();
	const { imageGalleryRef, contactRef, videoGalleryRef, aboutRef } =
		useScrollRefContext();
	const actions: CustomSpeedDialActionProps[] = [
		{
			icon: <Collections />,
			name: "Fotogalleri",
			scrollRef: imageGalleryRef,
		},
		{
			icon: <VideoLibrary />,
			name: "Videogalleri",
			scrollRef: videoGalleryRef,
		},
		{
			icon: <Info />,
			name: "Om bandet",
			scrollRef: aboutRef,
		},
		{
			icon: <Email />,
			name: "Kontakt",
			scrollRef: contactRef,
		},
		{
			icon: <Facebook />,
			name: "Facebook",
		},
	];
	return (
		<SpeedDial
			ariaLabel="SpeedDial basic example"
			sx={{ position: "absolute", top: 32, left: 32 }}
			icon={
				<Menu
					sx={{
						color: palette.secondary.main,
					}}
				/>
			}
			direction="down"
		>
			{actions.map((action) => getSpeedDialAction(action), actions)}
		</SpeedDial>
	);
};
